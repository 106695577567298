import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/users', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/users/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCountries(ctx, d) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/countries', { params: d })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRoles(ctx, d) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/roles', { params: d })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/users', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/users/${userData.id}`, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteUser(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/users/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeProfileImage(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/users/removeprofileimage', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changePassword(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/users/changepassword', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeSignatureImage(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/users/removesignature', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    transferCustomers(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/users-transfer-customers', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTargets(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/users-targets', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateTargets(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/users-targets', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
